import {
  ADD_TUGAS,
  ADD_TUGAS_FAIL,
  ADD_TUGAS_SUCCESS,
  GET_TUGAS,
  GET_TUGAS_FAIL,
  GET_TUGAS_SUCCESS,
  GET_ELEMENT_JSON,
  GET_ELEMENT_JSON_FAIL,
  GET_ELEMENT_JSON_SUCCESS,
} from "./actionTypes"

export const addTugas = data => {
  return {
    type: ADD_TUGAS,
    payload: data,
  }
}

export const addTugasSuccess = () => {
  return {
    type: ADD_TUGAS_SUCCESS,
  }
}

export const addTugasFail = data => {
  return {
    type: ADD_TUGAS_FAIL,
    payload: data,
  }
}

export const getTugas = data => {
  return {
    type: GET_TUGAS,
    payload: data,
  }
}

export const getTugasSuccess = data => {
  // console.log("data di action lemparan dari saga ???", data)
  return {
    type: GET_TUGAS_SUCCESS,
    payload: data,
  }
}

export const getTugasFail = data => {
  return {
    type: GET_TUGAS_FAIL,
    payload: data,
  }
}

export const getElementJson = () => {
  return {
    type: GET_ELEMENT_JSON,
  }
}

export const getElementJsonSuccess = data => {
  return {
    type: GET_ELEMENT_JSON_SUCCESS,
    payload: data,
  }
}

export const getElementJsonFail = data => {
  return {
    type: GET_ELEMENT_JSON_FAIL,
    payload: data,
  }
}
