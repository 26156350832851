import {
  ADD_TUGAS,
  ADD_TUGAS_FAIL,
  ADD_TUGAS_SUCCESS,
  GET_TUGAS,
  GET_TUGAS_FAIL,
  GET_TUGAS_SUCCESS,
  GET_ELEMENT_JSON,
  GET_ELEMENT_JSON_FAIL,
  GET_ELEMENT_JSON_SUCCESS,
} from "./actionTypes"
import {
  getElementJsonSuccess,
  getElementJsonFail,
  addTugasSuccess,
  addTugasFail,
  getTugasFail,
  getTugasSuccess,
} from "./actions"
import { call, put, takeEvery, select, takeLatest } from "redux-saga/effects"
import {
  getJsonElement,
  postAddTugas,
  getTugas,
} from "../../helpers/fakebackend_helper"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
const page = state => state.tugas.page
const limit = state => state.tugas.limit
const cari = state => state.tugas.cari

function* getJsonSaga() {
  try {
    // console.log("masuk get json di saga >>>")
    const response = yield call(getJsonElement)
    // console.log("response tugas di saga >>>", response)
    yield put(getElementJsonSuccess(response))
  } catch (error) {
    if (error.response) {
      yield put(getElementJsonFail(error))
      toast.error(error.response.data.message, { autoClose: 2000 })
    } else {
      toast.error("gagal mendapatkan data ", { autoClose: 2000 })
    }
    // console.log("error tugas di saga >>>", error)
  }
}

function* addTugasSaga({ payload: action }) {
  try {
    // console.log("masuk add tugas di saga >>>")
    // console.log("action add tugas di saga >>>", action)
    const response = yield call(postAddTugas, action)
    // console.log("response add tugas di saga >>>", response)
    toast.success("Berhasil menambahkan tugas", { autoClose: 2000 })
    yield put(addTugasSuccess())
    const pageState = yield select(page)
    const limitState = yield select(limit)
    const cariState = yield select(cari)

    const payloadGet = {
      limit: limitState,
      page: pageState,
      cari: cariState,
    }
    yield call(getTugasSaga, { payload: payloadGet })
  } catch (error) {
    if (error.response) {
      yield put(addTugasFail(error))
      toast.error(error.response.data.message, { autoClose: 2000 })
    } else {
      toast.error("gagal menambahkan tugas ", { autoClose: 2000 })
    }
  }
}

function* getTugasSaga({ payload: action }) {
  try {
    // console.log("action di saga ???", action)
    const response = yield call(getTugas, action)
    // console.log("response get tugas di saga >>>", response)
    yield put(getTugasSuccess(response))
  } catch (error) {
    // console.log("error get tugas di saga >>>", error)
    if (error.response) {
      yield put(getTugasFail(error))
      toast.error(error.response.data.message, { autoClose: 2000 })
    } else {
      toast.error("gagal mendapatkan tugas ", { autoClose: 2000 })
    }
  }
}

function* tugasSaga() {
  yield takeEvery(GET_ELEMENT_JSON, getJsonSaga)
  yield takeEvery(ADD_TUGAS, addTugasSaga)
  yield takeEvery(GET_TUGAS, getTugasSaga)
}

export default tugasSaga
